
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";

@Component
export default class WrongNetModal extends Vue {
  @Prop({
    default: false,
  })
  public value!: boolean;

  public input(value: boolean) {
    this.$emit("input", value);
  }

  private tapChange() {
    UserModule.ChangeChain();
  }
}
