
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import WrongNetModal from "@/components/wrong-net-modal.vue";
import chainIdCorrect from "@/utils/check-net";
import { AppModule } from "@/store/modules/app";
import AppSnackBar from "@/components/AppSnackBar.vue";

@Component({
  components:
    {
      AppSnackBar, WrongNetModal
    },
})
export default class APP extends Vue {
  private get chainId() {
    return UserModule.chainId;
  }

  private get showOverlay() {
    return AppModule.loading;
  }

  private get loadingTitle() {
    return AppModule.loadingTitle;
  }

  private showChangeNetModal = false;

  @Watch("chainId")
  private chainIdChanged() {
    console.log("chainId changed", UserModule.chainId)
    this.showChangeNetModal = !chainIdCorrect();
  }

  private created() {
    console.log(window.location.href);
  }
}
