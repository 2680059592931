import { UserModule } from '@/store/modules/user';

export default function chainIdCorrect() {
  return (
    `${UserModule.chainId}` === `${process.env.VUE_APP_CHAIN_ID}` ||
    `${UserModule.chainId}` === `${process.env.VUE_APP_CHAIN_ID_INT}`
  );
}

export function checkChainId(chainId: number) {
  return `${chainId}` === `${process.env.VUE_APP_CHAIN_ID}` || `${chainId}` === `${process.env.VUE_APP_CHAIN_ID_INT}`;
}
