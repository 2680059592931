
import Vue from "vue";
import Component from "vue-class-component";
import UserButton from "@/components/user-button.vue";
import logoName3 from "@/assets/image/logo-name/logo-name.png";
import AppBarUserButton from "@/components/AppBarUserButton.vue";
import AppBarLangButton from "@/components/LanguageButton.vue";

@Component({
  components: { UserButton, AppBarUserButton, AppBarLangButton },
})
export default class Home extends Vue {
  private logoname = logoName3;
}
