import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 960,
      md: 1441,
      lg: 1921,
    },
    scrollBarWidth: 0,
    mobileBreakpoint: 'sm',
  },
  theme: {
    default: 'light',
    options: {
      customProperties: true,
      variations: false,
    },
    themes: {
      light: {
        primary: '#2b3942',
        secondary: '#65676D',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        black: '#808080',
        green: '#57bb85',
        green_text: '#39ae4e',
        green_text13: '#39AE4E',
        main_bg: '#FFFFFF',
        item_bg: '#f4f5f9',
        item_bg2: '#FFFFFF',
        item_bg3: '#2b3442',
        button_gray: '#54606d',
        chip_bg: '#18212c',
        white: '#fff',
        sheet_bg: '#141a24',
      },
    },
  },
});
