import router from '@/router';
import { UserModule } from './store/modules/user';
import { Route } from 'vue-router';

router.beforeEach((to: Route, from: Route, next) => {
  if (UserModule.connected) {
    if (to.path === '/login') {
      next('/');
    } else {
      next();
    }
  } else if (to.path !== '/login') {
    next(`/login?redirect=${to.path}`);
  } else {
    next();
  }
});
