
import logo from "@/assets/logo.png";
import { Component } from "vue-property-decorator";
import Vue from "vue";
import { UserModule } from "@/store/modules/user";
import { ellipsisThree } from "@/utils/tools";

@Component
export default class UserButton extends Vue {
  private logo = logo;

  private tapCopy() {
    console.log("copy", this);
  }

  get userAddress() {
    return UserModule.address;
  }

  get showAddress(): string {
    if (this.userAddress) {
      return ellipsisThree(this.userAddress);
    }
    return "";
  }

  private tapDisconnect() {
    UserModule.Logout();
  }
}
