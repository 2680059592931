import CreateAccountModal from '@/components/CreateAccountModal.vue';
import { MapContractKit } from '@maplabs/map-atlas-sdk';
import _Vue from 'vue';
import Vuetify from './vuetify';

function CreateAccountPlugin(Vue: typeof _Vue): void {
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$createAccount = async (account: string, contractKit: MapContractKit) => {
    const ToastConstructor = Vue.extend(CreateAccountModal);
    const instance = new ToastConstructor();
    // TODO: this line is temporary to resolve bug ($vuetify not found)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    instance.$vuetify = Vuetify.framework;
    instance.$mount();
    document.body.appendChild(instance.$el);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-return-await
    return instance.createAccount(account, contractKit);
  };
}

export default CreateAccountPlugin;
