
import Vue from "vue";
import { Component } from "vue-property-decorator";
@Component({
})
export default class AppBarLangButton extends Vue {

  get currentLang() {
    return this.languages.find((item) => item.value === this.$i18n.locale);
  }

  get languages() {
    return [
      {value: 'en_US', text: 'English'},
      {value: 'zh', text: '简体中文'},
      {value: "kr", text: "한국어"},
      {value: "ru", text: "Русский"},
      {value: "tr", text: "Türkçe"},
    ];
  }

  private tapLang(lang: string) {
    this.$i18n.locale = lang;
  }
}
