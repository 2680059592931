import { Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';
import store from '@/store';

export interface IAppState {
  loading: boolean;
  loadingTitle: string;
  toastMsg: string;
  toastType: string;
  showToast: boolean;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'app',
})
class App extends VuexModule implements IAppState {
  public loading = false;

  public loadingTitle = '';

  public toastMsg = '';

  public toastType = 'info';

  public showToast = false;

  @Mutation
  public START_LOADING(title?: string) {
    if (title) {
      this.loadingTitle = title;
    }
    this.loading = true;
  }

  @Mutation
  public END_LOADING() {
    this.loading = false;
    this.loadingTitle = '';
  }

  @Mutation
  public TOAST(props: { type: string; message: string }) {
    this.toastMsg = props.message;
    this.toastType = props.type;
    this.showToast = true;
    setTimeout(() => {
      this.showToast = false;
    }, 15000);
  }

  @Mutation
  public HIDE_TOAST() {
    this.showToast = false;
  }
}

export const AppModule = getModule(App);
// export default User;
