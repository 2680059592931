import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '@/views/login.vue';
import Home from '@/views/home/index.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/lock',
    children: [
      {
        path: 'lock',
        name: 'Lock',
        component: () => import('@/views/lock/index.vue'),
      },
      {
        path: 'track',
        name: 'Track',
        component: () => import('@/views/track/staking-rewards-page.vue'),
      },
      {
        path: 'rewards',
        name: 'Rewards',
        component: () => import('@/views/track/staking-rewards-page.vue'),
      },
      {
        path: 'vote',
        name: 'Vote',
        component: () => import('@/views/vote/validators.vue'),
      },
      {
        path: 'active/:id',
        name: 'Active',
        component: () => import('@/views/vote/vote-history-details.vue'),
        props: true,
      },
      {
        path: 'addVote/:address',
        name: 'AddVote',
        component: () => import('@/views/vote/index.vue'),
        props: true,
      },
      {
        path: 'governance',
        name: 'Governance',
        component: () => import('@/views/governance/index.vue'),
        children: [
          {
            path: '',
            name: 'GovernanceDashboard',
            component: () => import('@/views/governance/DashboardPage.vue'),
          },
          {
            path: 'proposals',
            name: 'GovernanceProposals',
            component: () => import('@/views/governance/ProposalListPage.vue'),
            redirect: '/governance/proposals/ongoing',
            children: [
              {
                path: 'completed',
                name: 'GovernanceProposalsRejected',
                component: () => import('@/components/proposal-tables/ProposalTableCompleted.vue'),
              },
              {
                path: 'ongoing',
                name: 'GovernanceProposalProcessing',
                component: () => import('@/views/governance/list-pages/ProposalsProcessing.vue'),
                redirect: '/governance/proposals/ongoing/queued',
                children: [
                  {
                    path: 'queued',
                    name: 'GovernanceProposalQueue',
                    component: () => import('@/components/proposal-tables/ProposalTableQueue.vue'),
                  },
                  {
                    path: 'approval',
                    name: 'GovernanceProposalApproval',
                    component: () => import('@/components/proposal-tables/ProposalTableApproval.vue'),
                  },
                  {
                    path: 'referendum',
                    name: 'GovernanceProposalReferendum',
                    component: () => import('@/components/proposal-tables/ProposalTableReferendum.vue'),
                  },
                  {
                    path: 'execution',
                    name: 'GovernanceProposalExecution',
                    component: () => import('@/components/proposal-tables/ProposalTableExecution.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: 'proposal/:proposalID',
            name: 'ProposalDetail',
            component: () => import('@/views/governance/ProposalDetailPage.vue'),
            props: true,
          },
          {
            path: 'create-proposal',
            name: 'CreateProposal',
            component: () => import('@/views/governance/CreateProposalPage.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];

const router = new VueRouter({
  routes,
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
