import Vue from 'vue';
import Notifications from 'vue-notification';
import {
  actionTypeToName,
  ellipsis,
  percentBigNumberFixed,
  shortenNumber,
  statusText,
  timestampToHHMMSS,
  timestampToTime,
  timestampToTimeDot,
  viewportToPixels,
  weiBigNumberToNormalValue,
  weiBigNumberToNormalValueFixed,
} from '@/utils/tools';
import CreateAccountPlugin from '@/plugins/CreateAccount';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import '@/permission';
import apolloProvider from '@/apollo';

Vue.config.productionTip = false;
Vue.use(Notifications);

Vue.use(CreateAccountPlugin);

Vue.filter('bigNumberToNormalValue', weiBigNumberToNormalValue);
Vue.filter('weiBignumberToNormalValueFixed', weiBigNumberToNormalValueFixed);
Vue.filter('timestampToTime', timestampToTime);
Vue.filter('timestampToTimeDot', timestampToTimeDot);
Vue.filter('actionTypeToName', actionTypeToName);
Vue.filter('timestampToHHMMSS', timestampToHHMMSS);
Vue.filter('percentBigNumberFixed', percentBigNumberFixed);
Vue.filter('ellipsis', ellipsis);
Vue.filter('statusText', statusText);
Vue.filter('viewportToPixels', viewportToPixels);
Vue.filter('shortenNumber', shortenNumber);

new Vue({
  apolloProvider,
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
