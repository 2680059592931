import Clipboard from 'clipboard';
import { AppModule } from '@/store/modules/app';
import i18n from '@/i18n';

export const clipboardSuccess = () =>
  AppModule.TOAST({
    type: 'success',
    message: i18n.t('copySuccessfully') as string,
  });

export const clipboardError = () =>
  AppModule.TOAST({
    type: 'error',
    message: i18n.t('copyFailed') as string,
  });

export const handleClipboard = (text: string, event: MouseEvent) => {
  const clipboard = new Clipboard(event.target as Element, {
    text: () => text,
  });
  clipboard.on('success', () => {
    clipboardSuccess();
    clipboard.destroy();
  });
  clipboard.on('error', () => {
    clipboardError();
    clipboard.destroy();
  });
  (clipboard as any).onClick(event);
};
