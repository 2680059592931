
import Component from "vue-class-component";
import Vue from "vue";
import logoName from "@/assets/image/logo-name/logo-name.png";
import web3modal from "@/utils/web3modal";
import { UserModule } from "@/store/modules/user";
import { Route } from "vue-router";
import { Watch } from "vue-property-decorator";
import { AppModule } from '@/store/modules/app';

@Component
export default class Login extends Vue {
  private logoname = logoName;

  private redirect?: string | (string | null)[];

  private get loading(): boolean {
    return UserModule.logining;
  }

  private async tapConnect() {
    try {
      UserModule.SET_LOADING(true);
      await UserModule.Login();
      UserModule.SET_LOADING(false);
      await this.$router.push({
        path: this.redirect || "/",
      });
    } catch (e) {
      console.error(e, 'error');
      UserModule.SET_LOADING(false);
      UserModule.Logout();
      let msg = typeof e === 'string' ? e : e.message;
      AppModule.TOAST(
        {
          type: "error",
          message: msg,
        }
      );
    }
  }

  private init() {
    if (web3modal.cachedProvider) {
      this.tapConnect();
    }
  }

  private mounted() {
    this.init();
  }

  @Watch("$route", { immediate: true })
  private onRouteChange(route: Route) {
    const { query } = route;
    if (query) {
      this.redirect = query.redirect;
    }
  }
}
