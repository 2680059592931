import { floor } from 'lodash';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import i18n from '@/i18n';
export function calculateTimeStr(seconds: number): string {
  // 计算时间
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const resSeconds = Math.floor(seconds % 60);
  const daystr = days > 9 ? `${days}` : `0${days}`;
  const hourstr = hours > 9 ? `${hours}` : `0${hours}`;
  const minutestr = minutes > 9 ? `${minutes}` : `0${minutes}`;
  const secondstr = resSeconds > 9 ? `${resSeconds}` : `0${resSeconds}`;
  if (days > 0) {
    return `${daystr}:${hourstr}:${minutestr}:${secondstr}`;
  }
  return `${hourstr}:${minutestr}:${secondstr}`;
}

export function viewportToPixels(value: string) {
  const parts = value.match(/([0-9.]+)(vh|vw)/) as any;
  const q = Number(parts[1]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]];
  return side * (q / 100);
}

export function weiBigNumberToNormalValue(value?: BigNumber): string {
  if (value) {
    return Web3.utils.fromWei(value.toString(10));
  }
  return '0';
}

export function weiBigNumberToNormalValueFixed(value?: BigNumber): string {
  if (value) {
    return floor(parseFloat(Web3.utils.fromWei(value.toString(10))), 2).toFixed(2);
  }
  return '0';
}

export function normalValueToWeiBignumber(value?: number | string): BigNumber {
  if (value) {
    const res = Web3.utils.toWei(`${value}`);
    return new BigNumber(res, 10);
  }
  return new BigNumber(0);
}

export function percentBigNumberFixed(value: BigNumber): string {
  return value.multipliedBy(new BigNumber(100)).toFixed(2);
}

export function addressIsEqual(address: string, address1: string): boolean {
  if (address === null || typeof address === 'undefined' || address1 === null || typeof address1 === 'undefined') {
    return false;
  }
  return address.toLowerCase() === address1.toLowerCase();
}

/**
 * 生成随机字符串
 * @param {number} e 随机字符串长度
 * @returns {string}
 */
export function randomString(e: number): string {
  const aE = e || 32;
  const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  const a = t.length;
  let n = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < aE; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n;
}
//
// export function getWindowDpr() {
//   return window.devicePixelRatio
//     || window.webkitDevicePixelRatio
//     || window.mozDevicePixelRatio
//     || 1;
// }

// export function setupCanvasScale(canvasElement) {
//   // 处理高分辨率问题
//   const dpr = getWindowDpr();
//   const oldWidth = canvasElement.width;
//   const oldHeight = canvasElement.height;
//   // eslint-disable-next-line no-param-reassign
//   canvasElement.width = Math.round(oldWidth * dpr);
//   // eslint-disable-next-line no-param-reassign
//   canvasElement.height = Math.round(oldHeight * dpr);
//   const context = canvasElement.getContext('2d');
//   context.scale(dpr, dpr);
// }

export function ellipsis(value: string): string {
  const len = value.length;
  if (!value) return '';
  if (value.length > 20) {
    return `${value.substring(0, 8)}......${value.substring(len - 8, len)}`;
  }
  return value;
}

export function ellipsisThree(value: string): string {
  if (typeof value === 'undefined' || value === null || value.length === 0) {
    return '';
  }
  const len = value.length;
  if (!value) return '';
  if (value.length > 20) {
    return `${value.substring(0, 5)}...${value.substring(len - 4, len)}`;
  }
  return value;
}

export function ellipsisEnd(value: string): string {
  if (typeof value === 'undefined' || value === null || value.length === 0) {
    return '';
  }
  if (!value) return '';
  if (value.length > 20) {
    return `${value.substring(0, 5)}...`;
  }
  return value;
}

function generateLightColorHex(): string {
  let color = '#';
  for (let i = 0; i < 3; i += 1) {
    color += `0${Math.floor(((1 + Math.random()) * (16 * 16)) / 2).toString(16)}`.slice(-2);
  }
  return color;
}

export function getChartColors(count: number): string[] {
  const arr = [];
  for (let i = 0; i < count; i += 1) {
    const random = generateLightColorHex();
    arr.push(`${random}`);
  }
  return arr;
}

export function timestampToHHMMSS(timestamp: number) {
  const date = new Date(timestamp * 1000);
  return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

export function timestampToTime(timestamp: number, sep = '/') {
  const date = new Date(timestamp * 1000);
  return `${date.getFullYear()}${sep}${date.getMonth() + 1}${sep}${date.getDate()}`;
}

export function timestampToTimeDot(timestamp: number) {
  return timestampToTime(timestamp, '.');
}

export function statusText(stage: number) {
  switch (stage) {
    case 1:
      return i18n.t('pending2');
    case 2:
      return i18n.t('executed');
    case 3:
      return i18n.t('dequeued');
    case 4:
      return i18n.t('ready');
  }
}

export function shortenNumber(num: string | number) {
  let numb = typeof num === 'string' ? parseInt(num, 10) : num;
  numb = parseInt(numb.toFixed());
  if (numb) {
    if (numb >= 1000000000) {
      return `${Math.floor(numb / 1000000000)}K`;
    } else if (num >= 1000000) {
      return `${Math.floor(numb / 1000000)}M`;
    } else if (num >= 1000) {
      return `${Math.floor(numb / 1000)}M`;
    } else {
      return `${numb}`;
    }
  } else {
    return `${numb}`;
  }
}

export function actionTypeToName(type: number) {
  switch (type) {
    case 11:
      return i18n.t('lock2');
    case 12:
      return i18n.t('unlock2');
    case 13:
      return i18n.t('relock');
    case 14:
      return i18n.t('withdraw2');
    case 21:
      return i18n.t('vote2');
    case 22:
      return i18n.t('voteActive');
    case 23:
      return i18n.t('revokePending');
    case 24:
      return i18n.t('revokeActive');
  }
}
