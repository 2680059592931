
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";

@Component
export default class AppSnackBar extends Vue {
  private get type() {
    return AppModule.toastType;
  }

  private get message() {
    return AppModule.toastMsg;
  }

  private get show() {
    return AppModule.showToast;
  }

  private get icon() {
    switch (this.type) {
      case "info":
        return "mdi-information-outline";
      case "success":
        return "mdi-check-decagram";
      case "error":
        return "mdi-alert-circle";
      default:
        return "mdi-information-outline";
    }
  }

  private get color() {
    switch (this.type) {
      case "info":
        return "blue";
      case "error":
        return "error";
      case "success":
        return "success";
      default:
        return "blue";
    }
  }

  private tapClose() {
    AppModule.HIDE_TOAST();
  }
}
