
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { MapContractKit } from "@maplabs/map-atlas-sdk";
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
@Component
export default class CreateAccountModal extends Vue {
  private show = false;

  private account = "";

  private name = "";

  private resolve: ((value: any) => void) | null = null;

  private reject: ((value: any) => void) | null = null;

  // private contractKit: MapContractKit | null = null;

  public async createAccount(account: string, contractKit: MapContractKit) {
    this.account = account;
    console.log(contractKit);
    // this.contractKit = contractKit;
    return new Promise((resolve, reject) => {
      this.show = true;
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  private async tapCreate() {
    if (UserModule.contractKit) {
      try {
        AppModule.START_LOADING('Sending');
        const res = await UserModule.contractKit?.accountsContract.createAccountWithName(this.name);
        if (this.resolve) {
          this.show = false;
          this.resolve(res);
          AppModule.END_LOADING();
        }
      } catch (e) {
        AppModule.END_LOADING();
        AppModule.TOAST({
          type: 'error',
          message: e.message,
        })
        this.reject && this.reject(e as Error);
      }

    }
  }

  private tapClose() {
    if (this.reject) {
      this.show = false;
      this.reject(new Error("User denied create account"));
      AppModule.END_LOADING();
    }
  }
}
