
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { ellipsisThree } from "@/utils/tools";
import logo from "@/assets/logo.png";
import { handleClipboard } from "@/utils/clipboard";
import AvatarPixel from "@/components/AvatarPixel.vue";

@Component({
  components: { AvatarPixel },
})
export default class AppBarUserButton extends Vue {
  private logo = logo;

  get address() {
    return UserModule.address;
  }

  get showAddress() {
    return ellipsisThree(this.address);
  }

  private tapDisconnect() {
    UserModule.Logout();
  }

  private tapCopy(e: Event) {
    handleClipboard(this.address, e as MouseEvent);
  }
}
