
import { getNumber, getRandomColor } from "@/utils/avatarColors";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

const ELEMENTS = 64;
const SIZE = 80;

@Component
export default class AvatarPixel extends Vue {
  @Prop({
    default: "",
  })
  public name!: string;

  @Prop({
    default: () => ["#F0DDBD", "#BA3622", "#851E25", "#520C30", "#1C997F"],
  })
  public colors!: string[];

  @Prop({
    default: 28,
  })
  public size?: number;

  @Prop({
    default: false,
  })
  public square?: boolean;

  private viewboxSize = SIZE;

  private get properties() {
    return this.generateColors();
  }

  private generateColors() {
    let name = this.name;
    if (!name) {
      name = "map vote";
    }
    const numFromName = getNumber(name);
    const range = this.colors && this.colors.length;

    return Array.from({ length: ELEMENTS }, (_, i) => ({
      color: getRandomColor(numFromName % (i + 13), this.colors, range),
    }));
  }
}

// export default {
//   props: {
//     name: { type: String, required: true },
//     colors: { type: Array, required: true },
//     size: { type: Number, required: true },
//     square: { type: Boolean, default: false },
//   },
//   data() {
//     return {
//       viewboxSize: SIZE,
//     };
//   },
//   computed: {
//     properties() {
//       return this.generateColors();
//     },
//   },
//   methods: {
//     generateColors() {
//       const numFromName = getNumber(this.name);
//       const range = this.colors && this.colors.length;
//
//       const elementProperties =
//       Array.from({ length: ELEMENTS }, (_, i) =>
//       ({ color: getRandomColor(numFromName % (i + 13), this.colors, range) }));
//
//       return elementProperties;
//     },
//   },
// };
