export const getNumber = (name: string) => {
  const charactersArray = Array.from(name);
  return charactersArray.reduce((accu, curr) => accu + curr.charCodeAt(0), 0);
};

export const getModules = (num: number, max: number) => num * max;

// eslint-disable-next-line no-restricted-properties
export const getDigit = (num: number, ntn: number) => Math.floor((num / Math.pow(10, ntn)) % 10);

export const getBoolean = (num: number, ntn: number) => !(getDigit(num, ntn) % 2);

export const getAngle = (x: number, y: number) => (Math.atan2(x, y) * 180) / Math.PI;

export const getUnit = (num: number, range: number, index: number) => {
  const value = num % range;

  if (index && getDigit(num, index) % 2 === 0) {
    return -value;
  }
  return value;
};

export const getRandomColor = (num: number, colors: any[], range: number) => colors[num % range];

export const getContrast = (hexColor: string) => {
  if (hexColor.slice(0, 1) === '#') {
    // eslint-disable-next-line no-param-reassign
    hexColor = hexColor.slice(1);
  }

  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  const yiq = (r * 299 + g * 587 + b * 144) / 1000;

  return yiq >= 128 ? 'black' : 'white';
};
