import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';
import web3Modal from '@/utils/web3modal';
import store from '@/store';
import router from '@/router';
import Vue from 'vue';
import { MapContractKit } from '@maplabs/map-atlas-sdk';
import CreateAccount from '@/plugins/CreateAccount';
import chainIdCorrect from '@/utils/check-net';
import Web3 from 'web3';

export interface IUserState {
  name: string;
  avatar: string;
  address: string;
  web3: Web3 | null;
  connected: boolean;
  chainId: number;
  provider: any;
  contractKit: MapContractKit | null;
}

const GlobalConfig: any = {};

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'user',
})
class User extends VuexModule implements IUserState {
  public name = '';

  public avatar = '';

  public address = '';

  public connected = false;

  public chainId = -1;

  public logining = false;

  public isLoggedIn = false;

  constructor(props: any) {
    super(props);
    this.SET_ADDRESS = this.SET_ADDRESS.bind(this);
    this.SET_CONNECTED = this.SET_CONNECTED.bind(this);
    this.SET_WEB3 = this.SET_WEB3.bind(this);
    this.SET_CHAINID = this.SET_CHAINID.bind(this);
    this.SET_PROVIDER = this.SET_PROVIDER.bind(this);
    this.SET_LOADING = this.SET_LOADING.bind(this);
    this.Login = this.Login.bind(this);
    this.Logout = this.Logout.bind(this);
    this.ChangeChain = this.ChangeChain.bind(this);
  }

  get web3() {
    return GlobalConfig.web3;
  }

  get contractKit() {
    return GlobalConfig.contractKit;
  }

  get provider() {
    return GlobalConfig.provider;
  }

  @Mutation
  public SET_ADDRESS(address: string) {
    this.address = address;
  }

  @Mutation
  public SET_CONNECTED(connected: boolean) {
    this.connected = connected;
  }

  @Mutation
  public SET_LOGGED_IN(loggedIn: boolean) {
    this.isLoggedIn = loggedIn;
  }

  @Mutation
  public SET_WEB3(web3: Web3 | null) {
    if (web3) {
      GlobalConfig.web3 = web3;
      GlobalConfig.contractKit = new MapContractKit(web3);
    } else {
      GlobalConfig.web3 = null;
      GlobalConfig.contractKit = null;
    }
  }

  @Mutation
  public SET_CHAINID(chainId: number) {
    this.chainId = chainId;
  }

  @Mutation
  public SET_PROVIDER(provider: any) {
    GlobalConfig.provider = provider;
    // this.provider = JSON.parse(JSON.stringify(provider));
  }

  @Mutation
  public SET_LOADING(loading: boolean) {
    this.logining = loading;
  }

  @Action({ rawError: true })
  public async Login() {
    const provider = await web3Modal.connect();
    // TODO: 切换网络也需要做成 promise, 待完成(目前权宜之计)
    this.SET_PROVIDER(provider);
    provider.on('chainChanged', (chainId: number) => {
      console.log('chainid changed', chainId);
      // window.location.reload();
      this.SET_CHAINID(Number(chainId));
    });
    const web3 = new Web3(provider);
    const info = await Promise.all([web3.eth.getAccounts(), web3.eth.getChainId().then(Number)]);
    this.SET_CHAINID(info[1]);
    console.log(info, 'info');
    if (!chainIdCorrect()) {
      console.log('chain id wrong');
      throw Error('Wrong network');
    }
    const account = info[0][0];
    const contractKit = new MapContractKit(web3);
    GlobalConfig.contractKit = contractKit;
    const res = await Promise.all([contractKit.accountsContract.isAccount(account)]);
    Vue.use(CreateAccount);
    if (!(res[0] || res[1])) {
      this.SET_CHAINID(info[1]);
      await Vue.prototype.$createAccount(account, contractKit);
      console.log('create success');
      this.SET_ADDRESS(account);
      this.SET_CONNECTED(true);
      this.SET_WEB3(web3);
      this.SET_CHAINID(info[1]);
      provider.on('accountsChanged', (accs: string[]) => {
        if (accs.length === 0) {
          this.Logout();
        } else {
          this.Logout();
        }
      });
    } else {
      this.SET_CHAINID(info[1]);
      this.SET_ADDRESS(account);
      this.SET_CONNECTED(true);
      this.SET_WEB3(web3);
      this.SET_CHAINID(info[1]);
      provider.on('accountsChanged', (accs: string[]) => {
        if (accs.length === 0) {
          this.Logout();
        } else {
          this.Logout();
        }
      });
    }
  }

  @Mutation
  public Logout() {
    this.address = '';
    GlobalConfig.web3 = null;
    this.connected = false;
    web3Modal.clearCachedProvider();
    router.push('/login');
  }

  @Action
  public ChangeChain() {
    const params = {
      chainId: process.env.VUE_APP_CHAIN_ID, // A 0x-prefixed hexadecimal string
      chainName: process.env.VUE_APP_NETWORK_NAME,
      nativeCurrency: {
        name: process.env.VUE_APP_NETWORK_NAME,
        symbol: process.env.VUE_APP_SYMBOL_NAME,
        decimals: 18,
      },
      rpcUrls: [process.env.VUE_APP_RPC_URL],
      blockExplorerUrls: [process.env.VUE_APP_BLOCK_EXPORE_URL],
    };
    // throw Error('Current wallet do not support this');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    GlobalConfig.provider.request({
      method: 'wallet_addEthereumChain',
      params: [params, this.address],
    });
    // this.provider.request({
    //   method: 'wallet_addEthereumChain',
    //   params: [params, this.address],
    // });
  }
}

export const UserModule = getModule(User);
