import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
// import BefiLogo from '@/assets/image/befi_logo.png';
import OkxLogo from '@/assets/image/okx.png';
import GateLogo from '@/assets/image/gate.png';

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: 'a28dd8f58f12448dbe4627aea8c7796d', // required
      },
    },
    // 'custom-provider': {
    //   display: {
    //     logo: BefiLogo,
    //     name: 'Befi',
    //     description: 'Connect to your Befi Wallet',
    //   },
    //   package: window.ethereum,
    //   connector: async (ProviderPackage) => {
    //     const provider = ProviderPackage;
    //     await provider.enable();
    //     return provider;
    //   },
    // },
    'custom-okx': {
      display: {
        logo: OkxLogo,
        name: 'OKX Wallet',
        description: 'Connect to your OKX Wallet',
      },
      package: (window as any).okxwallet,
      connector: async (ProviderPackage) => {
        const provider = ProviderPackage;
        await provider.request({ method: 'eth_requestAccounts' });
        return provider;
      },
    },
    'custom-gate': {
      display: {
        logo: GateLogo,
        name: 'Gate Wallet',
        description: 'Connect to your Gate Wallet',
      },
      package: (window as any).gatewallet,
      connector: async (provider) => {
        await provider.request({ method: 'eth_requestAccounts' });
        console.log(provider.getChainId, 'get chainid', provider);
        return provider;
      },
    },
  },
});

export default web3Modal;
